<template>
  <div>
    <titulo-relatorio-imposto-renda />
    <filtro-relatorio-imposto-renda @filtrar="gerarRelatorioPdf" />
  </div>
</template>
    
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import breadcrumb from '@/common/utils/breadcrumb';

//services:
import FluxoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service.js';

// Components:
import FiltroRelatorioImpostoRenda from '@/views/financeiro/relatorios/RelatorioImpostoRenda/components/FiltroRelatorioImpostoRenda';
import TituloRelatorioImpostoRenda from '@/views/financeiro/relatorios/RelatorioImpostoRenda/components/TituloRelatorioImpostoRenda.vue';

export default {
  name: 'RelatorioIrmaos',
  components: {
    FiltroRelatorioImpostoRenda,
    TituloRelatorioImpostoRenda,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
      { titulo: this.$t('FINANCEIRO.RELATORIO_DECLARACAO_IMPOSTO_RENDA') },
    ]);
  },
  methods: {
    gerarRelatorioPdf(filtro) {
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.gerarImpostoDeRendaPdf(filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
    
<style>
</style>
    