<template>
  <div>
    <b-row>
      <b-col md="3">
        <input-select-search
          ref="anoLetivoId"
          v-model="localFiltro.anoLetivoId"
          :label="$t(`ACADEMICO.ANO_LETIVO`)"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>
      <b-col md="3">
        <input-select-search
          ref="tipoFiltro"
          v-model="tipoFiltro"
          :label="$t(`ACADEMICO.TIPO_FILTRO`)"
          :options="opcoes.tiposFiltro"
        />
      </b-col>
      <b-col md="3" v-if="tipoFiltro === 0">
        <input-select-search
          ref="matriculaId"
          v-model="localFiltro.matriculaId"
          :label="$t(`ACADEMICO.MATRICULAS`)"
          :options="opcoes.matriculas"
        />
      </b-col>
      <b-col md="3" v-if="tipoFiltro === 1">
        <input-select-search
          ref="cursoId"
          v-model="localFiltro.cursoId"
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
        />
      </b-col>
      <b-col md="3" v-if="tipoFiltro === 1">
        <input-select-search
          ref="grupoTurmaId"
          v-model="localFiltro.grupoTurmaId"
          :label="$t(`ACADEMICO.GRUPO_TURMA`)"
          :options="opcoes.grupoTurmas"
          :isDisabled="desabilitar.grupoTurma"
        />
      </b-col>
      <b-col md="3" v-if="tipoFiltro === 1">
        <input-select-search
          ref="turmaId"
          v-model="localFiltro.turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          :isDisabled="desabilitar.turma"
        />
      </b-col>
      <b-col md="auto">
        <b-button
          class="botao-acao-filtro mb-3 w-100"
          variant="info"
          @click="filtrar"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          class="botao-acao-filtro w-100"
          variant="secondary"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>  
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import MatriculaService from '@/common/services/matriculas/matriculas.service.js';
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  name: 'filtroRematricula',
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      desabilitar: {
        turma: true,
        grupoTurma: true,
      },
      localFiltro: {
        anoLetivoId: '',
        matriculaId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
      },
      tipoFiltro: 0,
      opcoes: {
        anosLetivos: [],
        matriculas: [],
        cursos: [],
        grupoTurmas: [],
        turmas: [],
        tiposFiltro: [
          {
            text: 'Por matricula',
            value: 0,
          },
          {
            text: 'Por turma',
            value: 1,
          },
        ],
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
    this.listarMatriculasSemPaginacao();
    this.getCursos();
    this.$emit('refs', this.$refs);
  },
  watch: {
    'localFiltro.cursoId': {
      handler(newValue) {
        if (newValue !== undefined) {
          this.getGrupoTurmas();
        }
      },
      deep: true,
    },
    'localFiltro.grupoTurmaId': {
      handler(newValue) {
        if (newValue !== undefined) {
          this.getTurmasPorCursoGrupoTurma();
        }
      },
      deep: true,
    },
    tipoFiltro: {
      handler(newValue) {
        if (newValue == 0) {
          this.filtro = {
            ...this.filtro,
            anoLetivoId: '',
            cursoId: '',
            grupoTurmaId: '',
            turmaId: '',
          };
        } else {
          this.filtro = {
            ...this.filtro,
            matriculaId: '',
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    listarMatriculasSemPaginacao() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                ...row,
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });
            this.opcoes.matriculas = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getGrupoTurmas() {
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.localFiltro.cursoId,
        this.localFiltro.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;

          if (!this.id) {
            this.desabilitar.grupoTurma = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurmasPorCursoGrupoTurma() {
      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorCursoIdGrupoTurmaId(this.localFiltro)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
          this.habilitarCampo('turma');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },

    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', this.localFiltro);
    },

    limparFiltro() {
      this.localFiltro = {
        anoLetivoId: '',
        matriculaId: '',
        curso: '',
        grupoTurma: '',
        turma: '',
        somentePagamentosEmDia: false,
      };
      this.$emit('limpar', this.localFiltro);
    },
  },
};
</script>